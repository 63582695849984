@use "../../tokens";

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;
  color: tokens.$color-grey-50;

  .buttonWrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-sm;
    min-width: tokens.$content-xs;
    padding-block-start: tokens.$spacing-md;
  }
}
