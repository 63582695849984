@use "../../../../../tokens";

.header,
.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: tokens.$content-lg;
}

.header {
  gap: tokens.$layout-xs;
  text-align: center;
}

.content {
  gap: tokens.$layout-lg;
}
