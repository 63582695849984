@use "../../../../../../../tokens";

.fixContainer {
  background-color: tokens.$color-grey-05;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // Set position relative so the child <Confetti> canvas can overlay <FixView>
  position: relative;

  @media screen and (min-width: tokens.$screen-md) {
    padding: tokens.$layout-sm;
  }

  .fixWrapper {
    box-shadow: tokens.$box-shadow-xs;
    border-radius: tokens.$border-radius-md;
    max-width: tokens.$content-xl;
    width: 100%;
    padding: tokens.$layout-xs;
    background-color: tokens.$color-white;
    background-size: cover;
    background-position: center;
    position: relative;
    background-image: url("./images/content-background.svg");
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: tokens.$content-sm;

    &.contentBackgroundImage {
      background-image: url("./images/high-risk-breaches-content-background.svg");
    }
  }

  .navClose {
    position: absolute;
    right: tokens.$spacing-md;
    top: tokens.$spacing-md;
  }

  .fixSection {
    display: flex;
    justify-content: stretch;
    align-items: center;
    gap: tokens.$spacing-md;

    @media screen and (min-width: tokens.$screen-md) {
      // Add horizontal padding to account for next/prev arrow placement on larger screens
      padding: tokens.$spacing-lg tokens.$spacing-2xl;
    }

    @media screen and (min-width: tokens.$screen-xl) {
      padding: tokens.$spacing-xl tokens.$spacing-lg tokens.$spacing-md
        tokens.$spacing-lg;
    }

    .navArrow {
      flex: 0 0 auto;
      display: none;
      position: absolute;
      align-items: center;
      justify-content: center;
      padding: tokens.$spacing-md;
      border-radius: tokens.$border-radius-sm;
      right: tokens.$spacing-md;

      &:hover {
        background-color: tokens.$color-violet-20;
      }

      @media screen and (min-width: tokens.$screen-md) {
        display: flex;
      }
    }

    .viewWrapper {
      flex: 1 1 auto;
    }
  }
}
