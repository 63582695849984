@use "../../tokens";

.dataBrokerProfileCardsWapper {
  text-align: center;
}

// This view shows the first X many data broker profile cards based on the break point. It uses nth-child to set/unset visibility.
.dataBrokerProfileCards {
  padding: tokens.$spacing-md 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: tokens.$spacing-lg;
  list-style: none;
  width: 100%;

  li {
    margin: 0;
    padding: 0;

    // Hide all cards after the first four at this breakpoint
    &:nth-child(n + 5) {
      display: none;
    }
  }

  // The `.showAll` class is active when user clicks "View more" button to see all data broker profiles. It overrides all the breakpoint specific logic to show every card.
  &.showAll {
    li {
      display: unset;
      @media screen and (min-width: tokens.$screen-md) {
        &:nth-child(n + 5) {
          display: unset;
        }
      }
      @media screen and (min-width: tokens.$screen-xl) {
        &:nth-child(n + 7),
        &:nth-child(n + 9) {
          display: unset;
        }
      }
    }
  }

  @media screen and (min-width: tokens.$screen-sm) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: tokens.$screen-md) {
    grid-template-columns: 1fr 1fr 1fr;

    li:nth-child(n + 5) {
      display: unset;
    }

    // Hide all cards after the first six at this breakpoint
    li:nth-child(n + 7) {
      display: none;
    }
  }
}

.dataBrokerProfileCard {
  background-color: tokens.$color-white;
  box-shadow: tokens.$box-shadow-sm;
  border-radius: tokens.$border-radius-sm;
  text-align: center;
  padding: tokens.$spacing-md tokens.$spacing-sm tokens.$spacing-md;
  font: tokens.$text-body-sm;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dataBrokerName {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: tokens.$spacing-sm;
  }

  .openInNewTab {
    color: tokens.$color-blue-50;
    display: inline-block;
  }
}

.viewProfilesToggle {
  appearance: none;
  background-color: transparent;
  border: 0;
  display: inline-flex;
  justify-items: center;
  align-items: center;
  gap: tokens.$spacing-sm;
  transition: transform 0.2s ease;
  cursor: pointer;

  &.active {
    img {
      transform: rotate(180deg);
    }
  }
}
