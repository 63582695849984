@use "../../../tokens";

.modalContent {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;
  padding: tokens.$spacing-sm;

  dl {
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-sm;
    padding: 0;

    dt {
      margin-bottom: tokens.$spacing-sm;
    }

    dd {
      background: url("../../../(proper_react)/(redesign)/(authenticated)/user/(dashboard)/dashboard/fix/images/icon-check-mark.svg")
        no-repeat left top;
      list-style: none;
      margin: 0;
      padding-left: tokens.$spacing-lg;
    }
  }

  .priceSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: tokens.$spacing-sm;

    .pricingInfoWrapper {
      display: flex;
      flex-direction: column;

      .pricingInfo {
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .pricingPill {
    margin-top: tokens.$spacing-lg;
    align-items: center;
    border-radius: tokens.$border-radius-xl;
    border: 2px solid tokens.$color-purple-70;
    display: flex;
    gap: tokens.$spacing-md;
    padding: tokens.$spacing-sm tokens.$spacing-lg;

    .pricingLabel {
      display: flex;
      flex-direction: column;
    }
  }

  .productCta {
    text-align: center;
    width: 100%;
  }
}
