@use "../../../../../../tokens";

.flagWrapper {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;
  background-color: tokens.$color-white;
  padding: tokens.$spacing-lg;
  border-radius: tokens.$border-radius-md;
  max-width: tokens.$content-sm;
}

.flagName {
  font: tokens.$text-title-3xs;
}

.enabledControl {
  display: flex;
  gap: tokens.$spacing-xs;
  font: tokens.$text-body-lg;
}

.allowListWrapper {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-md;

  h4 {
    font: tokens.$text-body-md;
    font-weight: bold;
  }
}

.allowList {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-xs;
  padding: 0;
  list-style-type: none;
}

.addressListing {
  display: flex;
  align-items: center;
  gap: tokens.$spacing-xs;
  font: tokens.$text-body-md;
  font-family: monospace;

  :first-child {
    flex-grow: 1;
  }

  button {
    background-color: transparent;
    border: none;
    border-radius: tokens.$border-radius-xl;
    aspect-ratio: 1;

    &:hover {
      cursor: pointer;
      background-color: tokens.$color-red-70;
      color: tokens.$color-white;
    }
  }

  &:has(button:hover) {
    color: tokens.$color-red-70;
  }
}

.addressAdder {
  display: flex;
  align-items: center;
  gap: tokens.$spacing-sm;

  input[type="email"] {
    flex-grow: 1;
  }

  // This rule is more specific than the button:hover in `.addressListing`,
  // but that class doesn't apply to the same elements:
  /* stylelint-disable no-descending-specificity */
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: transparent;
    color: tokens.$color-blue-50;
    border: none;
    border-radius: tokens.$border-radius-sm;
    padding: tokens.$spacing-sm;

    &:hover {
      cursor: pointer;
      background-color: tokens.$color-blue-50;
      color: tokens.$color-white;
    }
  }
  /* stylelint-enable no-descending-specificity */
}
