@use "../../../../../../../../tokens";

.triggerButton {
  @include tokens.question-mark-circle-btn;
}

.dialogContents {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;
  color: tokens.$color-grey-50;

  .confirmButtonWrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    min-width: tokens.$content-xs;
    padding-block-start: tokens.$spacing-md;
  }
}
