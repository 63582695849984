@use "../../../tokens";

.detailsFoundItem {
  .label {
    display: flex;
    align-items: center;
    gap: tokens.$spacing-xs;
    font-weight: 700;
  }

  .dataClassListDetailsWrapper {
    padding-inline-start: var(--exposureDetailsIconWidth);

    .dataClassListDetails {
      list-style: none;
      margin: 0;
      padding: tokens.$spacing-sm 0 0 0;
      gap: tokens.$spacing-xs;
      display: flex;
      flex-direction: column;

      li {
        list-style-type: none;
      }
    }
  }
}
