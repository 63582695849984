@use "../../../tokens";

.upsellBadge {
  align-items: center;
  background: none;
  border-radius: tokens.$border-radius-xl;
  border: 1px solid tokens.$color-grey-40;
  color: tokens.$color-black;
  cursor: pointer;
  display: flex;
  font: tokens.$text-body-md;
  font-weight: 600;
  gap: tokens.$spacing-sm;
  justify-content: center;
  padding: tokens.$spacing-sm tokens.$spacing-md;
  user-select: none;

  .toggleIndicator {
    background: tokens.$color-grey-40;
    border-radius: tokens.$border-radius-md;
    display: block;
    position: relative;
    outline: tokens.$border-focus-width solid tokens.$color-grey-40;
    height: 16px;
    width: 32px;

    &::after {
      background-color: tokens.$color-white;
      border-radius: 50%;
      content: "";
      display: block;
      height: 16px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 16px;
    }
  }

  &.isSelected {
    border-color: transparent;
    cursor: default;
    padding-block: 0;

    .toggleIndicator {
      background: none;
      outline: none;
      width: 16px;

      &::after {
        background-color: tokens.$color-green-50;
      }
    }
  }
}

.lastScanIndicator {
  display: flex;
  align-items: center;
  font: tokens.$text-body-xs;
  padding-inline: tokens.$spacing-md;
  position: absolute;
}
