@use "../../../tokens";

.underlay {
  position: fixed;
  inset: 0;
  background-color: rgba(tokens.$color-black, 0.5);
  // Overlap all regular content on the site with a z-index,
  // the highest of which (at the time of writing) are the <Steps> in the
  // onboarding flow, whose images have a z-index of 1:
  z-index: 2;

  .childrenWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
