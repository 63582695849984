@use "../../../../../../../tokens";

.container {
  background-color: tokens.$color-white;
  background-image: url("../images/dashboard-top-banner-wave.svg");
  background-position: center;
  background-size: cover;
  border-radius: tokens.$border-radius-md;
  border: 1px solid rgba(tokens.$color-purple-50, 0.2);
  display: flex;
  flex-direction: column-reverse;
  gap: tokens.$layout-sm;
  padding: tokens.$layout-xs;

  @media screen and (min-width: tokens.$screen-lg) {
    padding: tokens.$layout-md;
    flex-direction: row;
    align-items: center;
  }

  .chart {
    display: flex;
    justify-content: center;

    @media screen and (min-width: tokens.$screen-md) {
      flex: 1 1 0%;
    }
  }
}
