@use "../../tokens";

.progressCard {
  background: tokens.$color-white;
  border-radius: tokens.$border-radius-md;
  border: 2px solid rgba(tokens.$color-purple-70, 0.4);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: tokens.$spacing-lg;

  @media screen and (min-width: tokens.$screen-lg) {
    min-width: tokens.$content-sm;
  }

  .header {
    display: flex;
    align-items: center;
    gap: tokens.$spacing-sm;
    justify-content: center;
    font: tokens.$text-title-3xs;
    font-weight: 600;

    button {
      @include tokens.question-mark-circle-btn;
    }
  }

  .progressBarContainer {
    gap: tokens.$spacing-md;
    align-items: center;
    display: flex;

    img {
      width: 50px; // width of laptop images
      height: auto;
    }

    .fullProgressBar {
      border-radius: tokens.$border-radius-lg;
      height: 15px;
      background: repeating-linear-gradient(
        -45deg,
        tokens.$color-grey-20,
        tokens.$color-grey-20 5px,
        tokens.$color-grey-10 5px,
        tokens.$color-grey-10 10px
      );
      position: relative;
      width: 100%;

      .activeProgressBar {
        height: inherit;
        border-radius: tokens.$border-radius-lg;
        position: absolute;
        background: tokens.$gradient-blue;
      }

      .percentageBreakdown {
        font: tokens.$text-body-sm;
        color: tokens.$color-grey-60;
        margin-top: 15px; // offset height of progress bar
        padding-top: tokens.$spacing-xs;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .progressStatsWrapper {
    font: tokens.$text-body-sm;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    gap: tokens.$layout-md;
    padding: tokens.$spacing-md 0;
    justify-content: center;

    .progressItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;

      &:not(:first-child)::before {
        // Lines in between the different items in the progress cardå
        @media screen and (min-width: tokens.$screen-sm) {
          content: "";
          position: absolute;
          left: -(tokens.$layout-xs);
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: tokens.$color-grey-20;
        }
      }

      .progressStat {
        align-items: center;
        justify-content: center;
        display: flex;
        gap: tokens.$spacing-sm;

        img {
          display: none;

          @media screen and (min-width: tokens.$screen-sm) {
            display: block;
            width: 50px;
            height: auto;
          }
        }
      }

      span {
        font: tokens.$text-title-sm;
        color: tokens.$color-purple-70;

        @media screen and (min-width: tokens.$screen-sm) {
          font: tokens.$text-title-md;
        }

        @media screen and (min-width: tokens.$screen-xl) {
          font: tokens.$text-title-lg;
          font-weight: 600;
        }
      }

      &.greyedOut {
        img {
          filter: grayscale(100%);
        }

        span {
          color: tokens.$color-grey-40;
        }
        p {
          color: tokens.$color-grey-40;
          display: flex;
          align-items: center;
          gap: tokens.$spacing-xs;
        }
      }
    }
  }
}

.modalBodyContent {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-sm;

  .confirmButtonWrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    min-width: tokens.$content-xs;
    padding-block-start: tokens.$spacing-md;
  }
}
