@use "../../../../../../../tokens";

.explainerContentWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1 1 0%;

  @media screen and (min-width: tokens.$screen-sm) and (max-width: calc(tokens.$screen-md - 1px)) {
    justify-content: center;
    flex: 0.5 1 0%;
    text-align: center;
  }

  .explainerContent {
    display: flex;
    gap: tokens.$spacing-md;
    flex-direction: column;

    @media screen and (min-width: tokens.$screen-md) {
      max-width: tokens.$content-sm;
    }

    h3 {
      font: tokens.$text-title-2xs;
    }

    .cta {
      align-self: start;

      @media screen and (min-width: tokens.$screen-sm) and (max-width: calc(tokens.$screen-md - 1px)) {
        align-self: center;
      }

      @media screen and (max-width: calc(tokens.$screen-md - 1px)) {
        display: grid;
        max-width: tokens.$content-sm;
        width: 100%;
      }
    }
  }
}
