@use "../../tokens";

.toggleContainer {
  background-color: tokens.$color-grey-05;
  border-radius: tokens.$border-radius-xl;
  border: 2px solid tokens.$color-purple-70;
  padding: tokens.$spacing-xs;
  display: flex;
  align-items: center;
  align-self: center;
  gap: tokens.$spacing-xs;

  &:focus-within {
    outline: tokens.$border-focus-width solid tokens.$color-purple-10;
  }

  .option {
    border-radius: tokens.$border-radius-xl;
    // Add a bit more space: The next spacing step is too much.
    padding: tokens.$spacing-sm calc(tokens.$spacing-md * 1.5);
    line-height: 1.25em;
    font-weight: 500;
    color: tokens.$color-grey-50;
    cursor: pointer;

    &.isFocused {
      background-color: tokens.$color-purple-10;
    }

    &:hover {
      background-color: tokens.$color-purple-20;
    }

    &.isSelected {
      background-color: tokens.$color-purple-40;
      color: tokens.$color-white;
      font-weight: 600;
    }
  }
}
