@use "../../../tokens";

.exposureCard {
  font: tokens.$text-body-sm;
  background-color: tokens.$color-white;
  border: 2px solid rgba(tokens.$color-purple-70, 0.2);
  border-radius: tokens.$border-radius-md;

  .exposureHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: calc(tokens.$spacing-md * 1.25) tokens.$spacing-md;
    gap: tokens.$layout-xs;

    .chevron {
      flex-shrink: 0;
      background-color: transparent;
      border-style: none;
      padding: 0;
      cursor: pointer;
      color: rgba(tokens.$color-purple-70, 0.7);
      width: 15px; // size of the chevron on mobile
      height: 20px; // height of down chevron

      @media screen and (min-width: tokens.$screen-md) {
        width: 20px; // size of the chevron on larger devices
      }

      .isOpen {
        transition: transform 0.2s ease-out;
        rotate: (180deg);
      }
    }
  }

  dl.exposureHeaderList {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;
    width: 100%;
    justify-content: space-between;
    height: 30px; // fixed height to standardize image/icon heights

    .hideOnMobile {
      display: none;

      @media screen and (min-width: tokens.$screen-lg) {
        display: block;
      }
    }

    @media screen and (min-width: tokens.$screen-lg) {
      gap: tokens.$layout-sm;
    }

    .dataBrokerLogo {
      object-fit: contain;
    }

    .fallbackLogo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 50px;
      color: black;
      font: tokens.$text-title-3xs;
    }

    dd {
      display: flex;
      align-items: center;

      .exposureCompanyTitle {
        font: tokens.$text-body-sm;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media screen and (max-width: tokens.$screen-sm) {
          &.makeFontSmaller {
            font: tokens.$text-body-2xs;
          }
        }
      }

      .exposureImageWrapper {
        .exposureImage {
          height: 100%;
        }
      }

      @media screen and (min-width: tokens.$screen-lg) {
        &.exposureImageWrapper {
          flex: 0.2 0 tokens.$width-first-column-filter-bar;
        }
      }

      @media screen and (min-width: tokens.$screen-xl) {
        flex: 1 1 0%;

        &.hideOnMobile {
          display: flex;
        }
      }
    }
  }

  .exposureBreachLink {
    align-items: center;
    color: tokens.$color-blue-50;
    display: inline-flex;
    gap: 0.25em;
  }

  .exposureDetailsSection {
    margin: 0;
    overflow: hidden;
    padding: 0 tokens.$layout-md;
    height: 0;
    display: none;

    .exposureDetailsTopDescription {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: tokens.$layout-xs;

      @media screen and (min-width: tokens.$screen-sm) {
        flex-direction: row;
        justify-content: space-between;
      }

      @media screen and (min-width: tokens.$screen-md) {
        gap: tokens.$layout-xl;
      }

      span {
        flex: 0 0 auto;
      }
    }

    .exposureDetailsContent {
      margin-top: tokens.$layout-xs;
      background: tokens.$color-grey-05;
      padding: tokens.$spacing-md tokens.$spacing-lg;
      border-radius: tokens.$border-radius-md;

      .exposedDataTypes {
        margin-top: tokens.$spacing-md;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: tokens.$spacing-lg;
      }
    }

    .openInNewTab {
      color: tokens.$color-blue-50;

      svg {
        display: inline;
        margin: 0 tokens.$spacing-xs;
      }
    }

    &.isOpen {
      border-top: 2px solid rgba(tokens.$color-purple-70, 0.2);
      overflow: auto;
      height: auto;
      display: block;
      padding: tokens.$spacing-lg;

      @media screen and (min-width: tokens.$screen-lg) {
        padding: tokens.$spacing-xl tokens.$layout-xl;
      }
    }
  }
}

.visuallyHidden {
  @include tokens.visually-hidden;
}

.companyNameArea {
  @include tokens.company-name-area;
}

.resolveExposuresCtaDesktop {
  display: none;
  @media screen and (min-width: tokens.$screen-md) {
    display: block;
  }
}

.resolveExposuresCtaMobile {
  display: flex;
  justify-content: center;
  margin-top: tokens.$spacing-lg;

  @media screen and (min-width: tokens.$screen-md) {
    display: none;
  }
}

.manualResolutionPraise {
  display: flex;
  gap: tokens.$spacing-xs;
}
